.App {
  height: 100vh;
  /* This makes the .App take up the full height of its parent */
  display: flex;
  flex-direction: column;
  align-items: center;
}


.App-content {
  margin-left: auto;
  margin-right: auto;
  justify-content: center;
  overflow: hidden;
  -webkit-box-shadow: 0 2px 6px rgba(0, 0, 0, 0.2);
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.2);
  max-width: 450px;
  min-width: 320px;
  min-height: 338px;
  background-color: #ffffff;

}

.centered-div {
  line-height: 1.2;
  background-color: #fff;
  padding: 40px;
}


.input-group-prepend .input-group-text {
  background-color: transparent;
}

.bg-logo {
  /* padding-top: 10px; */
  /* align logo to top of window*/
  top: 10px;
  scale: 0.5;
  margin-left: 10px;
  align-items: center;
  pointer-events: none;
}


.input-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  margin-top: 10px;
}


.input-icon-wrapper {
  position: relative;
  width: 80%;
  /* center in div */

  margin: 0 auto;
  margin-top: 30px;
}

.input-icon {
  position: absolute;
  left: 15px;
  top: 50%;
  transform: translateY(-50%);
  color: #aaa;
}


.input-icon-right {
  position: absolute;
  right: 15px;
  top: 50%;
  transform: translateY(-50%);
  color: #aaa;
}

.debug {
  background-color: #fff;
}

.fa-spin {
  font-size: larger;
  margin-left: 10px; 
}

input[type="email"] {
  display: flex;
  border: 1px solid #ccc;
  box-sizing: border-box;
  font-size: 16px;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  font-weight: 600;
  color: #323130;
  padding-left: 40px;
  border-top-width: 0;
  border-left-width: 0;
  border-right-width: 0;
  
}

button {
  display: flex;
  padding: 10px 30px;
  margin-top: 30px;
  color: #fff;
  border-color: #0067b8;
  border: none;
  margin-left: auto;
  background-color: #0067b8;
  font-weight: 650;
  font-size: 18px;
  font:inherit;
  cursor: pointer;
  min-width: 108px;
  line-height: normal;
}

button:disabled,
button:disabled:hover {
  background-color: #ccc;
  cursor: not-allowed;
}

button:hover {
  /* background-color: #389c87; */
}

.App-header {
  margin-bottom: 20px;
}


.App-content p {
  font-size: 16px;
  margin-bottom: 10px;
}


.my-modal {
  position: absolute;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 20px;
  width: 80%;
  max-width: 400px;
}

.my-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.75);
}


.my-modal h2 {
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 10px;
}

.my-modal p {
  font-size: 16px;
  margin-bottom: 20px;
}

.my-modal button {
  position: relative;
  left: 50%;
  transform: translateX(-50%);
}

.my-modal .btn-secondary {
  background-color: #ccc;
  color: #fff;
  position: relative;
  left: 50%;
  transform: translateX(-50%);
  font-weight: 650;
  border: none;
  border-radius: 50px;
  font-size: 18px;
}

.warning-box {
  display: flex;
  flex-direction: row;
  align-items: left;
  background-color: #ffffff;
  color: #333333;
  width: 30%;
  padding: 10px;
  padding-left: 20px;
  line-height: 1.5;
  border: 1px;
  border-radius: 10px;
  font-family: "Google Sans", roboto, "Noto Sans Myanmar UI", arial, sans-serif;
  position: absolute;
  top: 10px;
  left: 10px;  
}

/* hide warning box for narrow */
@media (max-width: 768px) {
  .warning-box {
    display: none;
  }
}

.warning-logo {
  flex-basis: 36px;
  margin-right: 16px;
  max-height: 40px;
  margin-top: 30px;
}

.app-logo {
  flex-basis: 36px;
  margin-right: 16px;
  max-height: 40px;
  margin-top: 0px;
  margin-bottom: 10px;
}

.warning-text {
  flex-basis: 80%;
  margin-top: 10px;
  font-size: 1Srem;
  direction: ltr;
  line-height: 1.42857143;
}

.App-content h1 {
  font-size: 1.5rem;
  font-weight: 600;
  margin-bottom: 20px;
}

.warning-box h1 {
  font-size: 1.5rem;
  font-weight: 600;
  
}